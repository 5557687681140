


<div class="container-fluid" *ngIf="!disable" >

 <div>
  <h1 style="text-decoration-line: underline; margin-top: 40px;">Extraction route optique </h1>
 </div>

 <mat-divider></mat-divider>
  
 <div class="baserop-container" >
  <div class="baserop-content" *ngFor="let file of files" >


      <mat-card  class="example-card" >


        <mat-card-header >
          <mat-card-title style="font-size: small;" >{{file['fileName'] | uppercase}}</mat-card-title>
    
        </mat-card-header>
        <mat-card-content>
          <p  class="title_file"> Taille : {{file['size'] | fileSize}}  </p>
          <p  class="title_file"> Format : zip  </p>
          <p class="title_file">Date  : {{file['createdOn'] | date : 'longDate' }}  </p>
        </mat-card-content>
        
        <mat-card-actions>
    
          <button matTooltip="Télécharger"  mat-raised-button style="border: 0px whitesmoke;" (click)="downloadBaseRop(file['nodeRef'], file['fileName']) ; apiService.openSnackBar(file['fileName'], '...') ">
            <i class="material-icons">download</i>
                       </button>

    
        </mat-card-actions>

      </mat-card>
    
    
    </div>


</div>



</div>

<app-underconstruction *ngIf="disable"></app-underconstruction>

